@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  font-family: "Poppins", sans-serif;
}

body {
  overflow-x: hidden;
  background-color: #fff;
}

*::selection {
  color: #252525;
  background-color: #eee;
}

*:focus {
  outline: none;
}

svg {
  cursor: pointer;
}
